@tailwind base;
@tailwind components;
@tailwind utilities;




/* =========== */

@import "./common.css";



* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}


html,
body {
  height: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  scroll-behavior: smooth;
}



a {
  color: inherit;
  text-decoration: none;
}



 /* ################################################################################################## */
