/* WebKit-based (Chrome, Safari, Edge) */
body::-webkit-scrollbar {
    width: 8px;
}

body::-webkit-scrollbar-track {
    background: #f1f1f1;
}

body::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
}

/* Firefox */
body {
    scrollbar-width: thin;
    /* For Firefox */
}

body {
    scrollbar-color: #888 #f1f1f1;
    /* For Firefox */
}